import React from "react";
import PropTypes from "prop-types";
import { login } from "../actions/auth";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { TextField, InputAdornment, Button } from "@material-ui/core";

const styles = theme => ({
  margin: {
    margin: theme.spacing.unit + 5
  },
  button: {
    margin: "0 auto",
    marginTop: "30px",
  }
});

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: ""
    };
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  }

  handleSubmit = async () => {
    await this.props.login(this.state);
    await this.props.isLogin && this.props.history.push("/");
  }

  render() {
    const { classes } = this.props;
    let { username, password } = this.state;

    return (
      <div className="login-page">
        <div className="login-form">
          <h3>Login</h3>
          <div className="content-form">
            <TextField
              className={classes.margin}
              fullWidth
              placeholder="Username"
              name="username"
              value={username}
              onChange={this.handleChange("username")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle />
                  </InputAdornment>
                )
              }}
            />
            <TextField
              className={classes.margin}
              fullWidth
              type="password"
              value={password}
              onChange={this.handleChange("password")}
              placeholder="Password"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle />
                  </InputAdornment>
                )
              }}
            />
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() =>this.handleSubmit()}
            >
              Login
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = store => {
  return {
    isLogin: store.auth.isLogin
  }
}

export default connect(mapStateToProps, {
  login
})(withStyles(styles)(Login));
