import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Dashboard, Login } from "./index";
import { connect } from "react-redux";
import { login } from "../actions/auth";

class Main extends React.Component {
  constructor(props){
    super(props);
    this.state = {

    }
  }

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/signin" component={Login} />
          <Route path="/" component={Dashboard} />
        </Switch>
      </BrowserRouter>
    )
  };
}

const mapStateToProps = store => {
  console.log(store);
  return {
    isLogin: store.auth.isLogin
  }
}

export default connect(mapStateToProps, {
  login
})(Main);