export const ACTION_LOGIN = "ACTION_LOGIN";
export const ACTION_CREATE_PRODUCT = "ACTION_CREATE_PRODUCT";
export const ACTION_ALL_PRODUCTS = "ACTION_ALL_PRODUCTS";
export const ACTION_GET_PRODUCT = "ACTION_GET_PRODUCT";

export default {
  ACTION_LOGIN,
  ACTION_CREATE_PRODUCT,
  ACTION_ALL_PRODUCTS,
  ACTION_GET_PRODUCT,
}