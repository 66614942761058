import React from "react";

class CreateCompany extends React.Component {
  constructor(props){
    super(props);
    this.state = {

    }
  }

  render() {
    return (
      <div>
        CreateCompany
      </div>
    )
  };
}

export default CreateCompany;