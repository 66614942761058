import { ACTION_LOGIN } from "../actions/actionTypes";

let initData = {
  isLogin: true,
  token: null
};

export default function(state = initData, action) {
  switch (action.type) {
    case ACTION_LOGIN:
      return {
        isLogin: true,
        token: action.date
      };
    default:
      return state;
  }
}
