import React from "react";
import { Switch, Route, Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {
  Products,
  CreateProduct,
  CreateCompany,
  Users,
  CreateUser,
  Companies,
  ProductDetail
} from "./index";
import {
  ListItem,
  Toolbar,
  Drawer,
  Menu,
  MenuItem,
  AppBar,
  List,
  ListItemIcon,
  ListItemText,
  IconButton,
  Divider,
  Typography,
  CssBaseline
} from "@material-ui/core";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

const drawerWidth = 260;

const styles = theme => ({
  root: {
    display: "flex"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap"
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: theme.spacing.unit * 7 + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing.unit * 9 + 1
    }
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3
  },
  grow: {
    flexGrow: 1
  }
});

// function ListItemLink(props) {
//   return <ListItem button component="a" {...props} />;
// }

// const ListItemLink = ({ icon, primary, secondary, to }) => (
//   <li>
//     <ListItem button component={props => <Link to={to} {...props} />}>
//       {icon && <ListItemIcon>{icon}</ListItemIcon>}
//       <ListItemText inset primary={primary} secondary={secondary} />
//     </ListItem>
//   </li>
// );

class Dashboard extends React.Component {
  state = {
    open: true,
    auth: true,
    anchorEl: null
  };

  componentWillMount = () => {
    !this.props.isLogin && this.props.history.push("/signin");
  };

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { classes, theme, match } = this.props;
    let { auth, anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={classNames(classes.appBar, {
            [classes.appBarShift]: this.state.open
          })}
        >
          <Toolbar disableGutters={!this.state.open}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerOpen}
              className={classNames(classes.menuButton, {
                [classes.hide]: this.state.open
              })}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" className={classes.grow}>
              Photos
            </Typography>
            {auth && (
              <div>
                <IconButton
                  aria-owns={open ? "menu-appbar" : undefined}
                  aria-haspopup="true"
                  onClick={this.handleMenu}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right"
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                  }}
                  open={open}
                  onClose={this.handleClose}
                >
                  <MenuItem onClick={this.handleClose}>Profile</MenuItem>
                  <MenuItem onClick={this.handleClose}>My account</MenuItem>
                </Menu>
              </div>
            )}
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          className={classNames(classes.drawer, {
            [classes.drawerOpen]: this.state.open,
            [classes.drawerClose]: !this.state.open
          })}
          classes={{
            paper: classNames({
              [classes.drawerOpen]: this.state.open,
              [classes.drawerClose]: !this.state.open
            })
          }}
          open={this.state.open}
        >
          <div className={classes.toolbar}>
            <IconButton onClick={this.handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </div>
          <Divider />
          <List>
            <ListItem component={Link} to="/tao-san-pham">
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary={"Tạo Sản Phẩm"} />
            </ListItem>
          </List>
          <List>
            <ListItem component={Link} to="/danh-sach-san-pham">
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary={"Danh sách Sản Phẩm"} />
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem component={Link} to="/tao-cong-ty">
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary={"Tạo Công Ty"} />
            </ListItem>
          </List>
          <List>
            <ListItem component={Link} to="/danh-sach-cong-ty">
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary={"Danh sách Công Ty"} />
            </ListItem>
          </List>
          <List>
            <ListItem component={Link} to="/tao-nguoi-dung">
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary={"Tạo Người Dùng"} />
            </ListItem>
          </List>
          <List>
            <ListItem component={Link} to="/danh-sach-nguoi-dung">
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary={"Người Dùng"} />
            </ListItem>
          </List>
          <Divider />
        </Drawer>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Switch>
            <Route
              path={`${match.url}tao-san-pham`}
              component={CreateProduct}
            />
            <Route
              path={`${match.url}danh-sach-san-pham/:id`}
              component={ProductDetail}
            />
            <Route
              path={`${match.url}danh-sach-san-pham`}
              component={Products}
            />
            <Route path={`${match.url}tao-cong-ty`} component={CreateCompany} />
            <Route
              path={`${match.url}danh-sach-cong-ty`}
              component={Companies}
            />
            <Route path={`${match.url}tao-nguoi-dung`} component={CreateUser} />
            <Route
              path={`${match.url}danh-sach-nguoi-dung`}
              component={Users}
            />
          </Switch>
        </main>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const mapStateToProps = store => {
  return {
    isLogin: store.auth.isLogin
  };
};

export default connect(
  mapStateToProps,
  {}
)(withStyles(styles, { withTheme: true })(withRouter(Dashboard)));
