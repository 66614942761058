/* eslint-disable no-unused-vars */
/* eslint-disable no-loop-func */
import {
  ACTION_CREATE_PRODUCT,
  ACTION_ALL_PRODUCTS,
  ACTION_GET_PRODUCT
} from "./actionTypes";
import { URL_API } from "../config/index";
import { request } from "../config/api";

// create product
export function setDataProduct(data) {
  return {
    type: ACTION_CREATE_PRODUCT,
    data
  };
}

function getFiles(files) {
  return Promise.all(files.map(file => getFile(file)));
}

//take a single JavaScript File object
function getFile(file) {
  var reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(new Error("Error parsing file"));
    };
    reader.onload = function() {
      //This will result in an array that will be recognized by C#.NET WebApi as a byte[]
      let bytes = Array.from(new Uint8Array(this.result));

      //if you want the base64encoded file you would use the below line:
      let base64StringFile = btoa(
        bytes.map(item => String.fromCharCode(item)).join("")
      );

      //Resolve the promise with your custom file structure
      resolve({
        // bytes: bytes,
        src: "data:image/png;base64," + base64StringFile,
        name: file.name,
        type: file.type,
        size: file.size
      });
    };
    reader.readAsArrayBuffer(file);
  });
}

export function createProduct(data) {
  return async dispatch => {
    let fd = new FormData();

    let customJsonFiles = await getFiles(data.danh_sach_hinh_anh);
    //customJsonFiles is an array of your custom files

    for (var key in data) {
      if (key === "danh_sach_hinh_anh") {
        fd.append("danh_sach_hinh_anh", JSON.stringify(customJsonFiles));
      } else {
        fd.append(key, data[key]);
      }
    }

    return request()
      .post(`${URL_API}/api/san-pham-cua-cong-ty/`, fd)
      .then(response => {
        console.log(response);
        dispatch(setDataProduct(data));
      });
  };
}

// list products
export function setDataProducts(data) {
  return {
    type: ACTION_ALL_PRODUCTS,
    data
  };
}
export function allProducts() {
  return dispatch => {
    return request()
      .get(`${URL_API}/api/san-pham-cua-cong-ty/`)
      .then(response => {
        dispatch(setDataProducts(response.data));
      });
  };
}

// detail
export function setDataProductDetail(data){
  return {
    type: ACTION_GET_PRODUCT,
    data
  }
}

export function getDetailProduct(id){
  return dispatch => {
    return request().get(`${URL_API}/api/san-pham-cua-cong-ty/${id}/`)
    .then(response => {
      dispatch(setDataProductDetail(response.data));
    })
  }
}

export default {
  createProduct,
  allProducts,
  getDetailProduct
};
