import { ACTION_CREATE_PRODUCT, ACTION_ALL_PRODUCTS, ACTION_GET_PRODUCT } from "../actions/actionTypes";

const initData = {
  products: [],
  product: {},
  isCreated: false
};

export default function(state = initData, action) {
  switch (action.type) {
    case ACTION_CREATE_PRODUCT:
      return {
        ...state,
        isCreated: true,
        product: action.data
      };
    case ACTION_ALL_PRODUCTS:
      return {
        ...state,
        products: action.data
      }
    case ACTION_GET_PRODUCT:
      return {
        ...state,
        product: action.data
      }
    default:
      return state;
  }
}
