import React from "react";
import CKEditor from "react-ckeditor-component";
import ImageUploader from "react-images-upload";
import { Grid, TextField, Button } from "@material-ui/core";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getDetailProduct } from "../actions/product";

class ProductDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      content: "content",
      product: {
        ten_san_pham: "",
        ngay_dong_goi: "",
        han_su_dung: "",
        nguoi_dong_goi: "",
        huong_dan_su_dung: "",
        so_phat_hanh: "",
        muc_chat_luong: "",
        dung_tich_thuc: "",
        nguoi_kiem_tra_chat_luong: "",
        huong_dan_bao_quan: "",
        // ckeditor
        thong_tin_don_vi_san_xuat: "",
        thong_tin_nguyen_lieu: "",
        thong_tin_cac_chung_nhan: "",
        danh_sach_hinh_anh: [],
        hinh_anh_san_pham_cua_cong_ty: []
      }
    };
    this.onDrop = this.onDrop.bind(this);
    this.updateContent = this.updateContent.bind(this);
    this.onChangeSanXuat = this.onChangeSanXuat.bind(this);
    this.onChangeNguyenLieu = this.onChangeNguyenLieu.bind(this);
    this.onChangeChungNhan = this.onChangeChungNhan.bind(this);
  }

  componentWillMount = async () => {
    let { id } = this.props.match.params;
    console.log(id);
    await this.props.getDetailProduct(id);
    await this.setState({ product: this.props.product });
  };

  handleChangeInput = name => event => {
    let { product } = this.state;
    product[name] = event.target.value;
    this.setState({ product });
  };

  updateContent(newContent) {
    this.setState({
      content: newContent
    });
  }

  onChange(evt) {
    var newContent = evt.editor.getData();
    this.setState({
      content: newContent
    });
  }

  onChangeSanXuat(evt) {
    let newContent = evt.editor.getData();
    let { product } = this.state;
    product.thong_tin_don_vi_san_xuat = newContent
    this.setState({ product });
  }

  onChangeNguyenLieu(evt) {
    let newContent = evt.editor.getData();
    let { product } = this.state;
    product.thong_tin_nguyen_lieu = newContent
    this.setState({ product });
  }

  onChangeChungNhan(evt) {
    let newContent = evt.editor.getData();
    let { product } = this.state;
    product.thong_tin_cac_chung_nhan = newContent
    this.setState({ product });
  }

  onBlur(evt) {
    console.log("onBlur event called with event info: ", evt);
  }

  afterPaste(evt) {
    console.log("afterPaste event called with event info: ", evt);
  }

  onDrop(picture) {
    console.log(picture);
    this.setState({
      danh_sach_hinh_anh: picture
    });
  }

  handleSubmit = async () => {
    console.log(this.state);
    await this.props.createProduct(this.state);
    (await this.props.isCreated) &&
      this.props.history.push("/danh-sach-san-pham");
  };

  render() {
    return (
      <div className="create-product">
        <h3 className="component-title">Thông Tin Sản Phẩm</h3>

        <Grid container spacing={24}>
          <Grid item md={4}>
            <TextField
              label="Tên Sản Phẩm"
              style={{ margin: 8 }}
              // placeholder="Placeholder"
              fullWidth
              margin="normal"
              value={this.state.product.ten_san_pham}
              onChange={this.handleChangeInput("ten_san_pham")}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item md={4}>
            <TextField
              label="Ngày đóng gói"
              style={{ margin: 8 }}
              // placeholder="Placeholder"
              fullWidth
              value={this.state.product.ngay_dong_goi}
              onChange={this.handleChangeInput("ngay_dong_goi")}
              margin="normal"
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item md={4}>
            <TextField
              label="Hạn Sử Dụng (tháng)"
              type="number"
              style={{ margin: 8 }}
              // placeholder="Placeholder"
              fullWidth
              value={this.state.product.han_su_dung}
              onChange={this.handleChangeInput("han_su_dung")}
              margin="normal"
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item md={4}>
            <TextField
              label="Người đóng gói"
              // type="number"
              style={{ margin: 8 }}
              // placeholder="Placeholder"
              fullWidth
              value={this.state.product.nguoi_dong_goi}
              onChange={this.handleChangeInput("nguoi_dong_goi")}
              margin="normal"
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item md={4}>
            <TextField
              label="Hướng dẫn sử dụng"
              // type="number"
              style={{ margin: 8 }}
              // placeholder="Placeholder"
              fullWidth
              value={this.state.product.huong_dan_su_dung}
              onChange={this.handleChangeInput("huong_dan_su_dung")}
              margin="normal"
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item md={4}>
            <TextField
              label="Số phát hành"
              // type="number"
              style={{ margin: 8 }}
              // placeholder="Placeholder"
              fullWidth
              value={this.state.product.so_phat_hanh}
              onChange={this.handleChangeInput("so_phat_hanh")}
              margin="normal"
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item md={4}>
            <TextField
              label="Mức chất lượng"
              // type="number"
              style={{ margin: 8 }}
              // placeholder="Placeholder"
              fullWidth
              value={this.state.product.muc_chat_luong}
              onChange={this.handleChangeInput("muc_chat_luong")}
              margin="normal"
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item md={4}>
            <TextField
              label="Dung tích thực"
              // type="number"
              style={{ margin: 8 }}
              // placeholder="Placeholder"
              fullWidth
              value={this.state.product.dung_tich_thuc}
              onChange={this.handleChangeInput("dung_tich_thuc")}
              margin="normal"
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item md={4}>
            <TextField
              label="Người kiểm tra chất lượng"
              // type="number"
              style={{ margin: 8 }}
              // placeholder="Placeholder"
              fullWidth
              value={this.state.product.nguoi_kiem_tra_chat_luong}
              onChange={this.handleChangeInput("nguoi_kiem_tra_chat_luong")}
              margin="normal"
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item md={4}>
            <TextField
              label="Hướng dẫn bảo quản"
              // type="number"
              style={{ margin: 8 }}
              // placeholder="Placeholder"
              fullWidth
              value={this.state.product.huong_dan_bao_quan}
              onChange={this.handleChangeInput("huong_dan_bao_quan")}
              margin="normal"
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
        </Grid>

        <h3 className="component-title">Thông tin đơn vị sản xuất</h3>
        <Grid container spacing={24}>
          <Grid item md={12}>
            <CKEditor
              activeClass="p1"
              name="333"
              content={this.state.product.thong_tin_don_vi_san_xuat}
              events={{
                blur: this.onBlur,
                afterPaste: this.afterPaste,
                change: this.onChangeSanXuat
              }}
            />
          </Grid>
        </Grid>

        <h3 className="component-title">Thông tin nguyên liệu</h3>
        <Grid container spacing={24}>
          <Grid item md={12}>
            <CKEditor
              activeClass="p2"
              name="2222"
              content={this.state.product.thong_tin_nguyen_lieu}
              events={{
                blur: this.onBlur,
                afterPaste: this.afterPaste,
                change: this.onChangeNguyenLieu
              }}
            />
          </Grid>
        </Grid>

        <h3 className="component-title">Thông tin các chứng nhận</h3>
        <Grid container spacing={24}>
          <Grid item md={12}>
            <CKEditor
              activeClass="p3"
              name="123"
              content={this.state.product.thong_tin_cac_chung_nhan}
              events={{
                blur: this.onBlur,
                afterPaste: this.afterPaste,
                change: this.onChangeChungNhan,
                name: "ck111"
              }}
            />
          </Grid>
        </Grid>

        <h3 className="component-title">Hình Ảnh / Video / Cup / Chứng nhận</h3>
        <Grid container spacing={24}>
          <Grid item md={12}>
            <ImageUploader
              withIcon={true}
              withPreview={true}
              buttonText="Choose images"
              withLabel={true}
              onChange={this.onDrop}
              imgExtension={[".jpg", ".gif", ".png", ".gif"]}
              maxFileSize={5242880}
            />
          </Grid>

          {this.state.product.hinh_anh_san_pham_cua_cong_ty &&
            this.state.product.hinh_anh_san_pham_cua_cong_ty.length > 0 &&
            this.state.product.hinh_anh_san_pham_cua_cong_ty.map(
              (item, index) => (
                <Grid key={index} item md={4}>
                  <img
                    src={item.src}
                    alt={item.name}
                    style={{ width: "100%" }}
                  />
                </Grid>
              )
            )}
        </Grid>

        <div className="action-submit">
          <Button
            variant="contained"
            color="primary"
            onClick={() => this.handleSubmit()}
          >
            Lưu thay đổi
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    product: store.product.product,
    isCreated: store.product.isCreated
  };
};

export default connect(
  mapStateToProps,
  {
    getDetailProduct
  }
)(withRouter(ProductDetail));
