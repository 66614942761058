import { ACTION_LOGIN } from "./actionTypes";
import { URL_API } from "../config/index";
import { request } from "../config/api";

// login
export function setDataLogin(data) {
  return {
    type: ACTION_LOGIN,
    data
  };
}
export function login(data) {
  return dispatch => {
    return request()
      .post(`${URL_API}/login/`, data)
      .then(response => {
        let { token } = response.data;
        localStorage.setItem("token", token);
        console.log(response.data);
        // dispatch(setDataLogin(token));
      })
      .catch(err => {
        console.log(err);
      });
  };
}
export default {
  login
};
